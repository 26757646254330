import 'amazeui/dist/css/amazeui.css'
import "../less/app.less";
import $ from "jquery";
import 'amazeui/dist/js/amazeui';
import 'animate.css';
const WOW = require('wowjs/dist/wow');
import Counter from "counter.js";

$(function () {
    // banner轮播
    $('#slider-banner').flexslider({
        playAfterPaused: 5000,
        before: function (slider) {
            if (slider._pausedTimer) {
                window.clearTimeout(slider._pausedTimer);
                slider._pausedTimer = null;
            }
        },
        after: function (slider) {
            var pauseTime = slider.vars.playAfterPaused;
            if (pauseTime && !isNaN(pauseTime) && !slider.playing) {
                if (!slider.manualPause && !slider.manualPlay && !slider.stopped) {
                    slider._pausedTimer = window.setTimeout(function () {
                        slider.play();
                    }, pauseTime);
                }
            }
        },
        smoothHeight: true,
        pauseOnHover: true,
        directionNav: false
    });

    //合作伙伴轮播
    $('#slider-partner').flexslider({
        playAfterPaused: 5000,
        before: function (slider) {
            if (slider._pausedTimer) {
                window.clearTimeout(slider._pausedTimer);
                slider._pausedTimer = null;
            }
        },
        after: function (slider) {
            var pauseTime = slider.vars.playAfterPaused;
            if (pauseTime && !isNaN(pauseTime) && !slider.playing) {
                if (!slider.manualPause && !slider.manualPlay && !slider.stopped) {
                    slider._pausedTimer = window.setTimeout(function () {
                        slider.play();
                    }, pauseTime);
                }
            }
        },
        smoothHeight: true,
        pauseOnHover: true,
        directionNav: false
    });

    // header禁止页面滚动
    $("header button.am-topbar-btn").on("click", function () {
        if ($("#doc-topbar-collapse").hasClass("am-in")) {
            $("body").removeClass("lock");
            $("#header-modal").hide();
        } else {
            $("body").addClass("lock");

            $("#header-modal").show();
        }
    })

    // 滚动监听动画
    window.wow = new WOW.WOW({
        live: false,
        offset: 80
    });
    window.wow.init();

    // 数字动画
    const counter1 = new Counter({
        start: 0,
        end: parseInt($("#counter1").attr("num")),
        selector: document.getElementById('counter1'),
        done: () => {
            var $ele = $('#counter1');
            var span = "<span>+</span>";
            $(span).hide().appendTo($ele).fadeIn();
        }
    });
    const counter2 = new Counter({
        start: 0,
        end: parseInt($("#counter2").attr("num")),
        selector: document.getElementById('counter2'),
        done: () => {
            var $ele = $('#counter2');
            var span = "<span>+</span>";
            $(span).hide().appendTo($ele).fadeIn();

        }
    });
    const counter3 = new Counter({
        start: 0,
        end: parseInt($("#counter3").attr("num")),
        selector: document.getElementById('counter3'),
        done: () => {
            var $ele = $('#counter3');
            var span = "<span>+</span>";
            $(span).hide().appendTo($ele).fadeIn();

        }
    });
    const counter4 = new Counter({
        start: 0,
        end: parseInt($("#counter4").attr("num")),
        selector: document.getElementById('counter4'),
        done: () => {
            var $ele = $('#counter4');
            var span = "<span>+</span>";
            $(span).hide().appendTo($ele).fadeIn();

        }
    });
    const counter5 = new Counter({
        start: 0,
        end: parseInt($("#counter5").attr("num")),
        selector: document.getElementById('counter5'),
        done: () => {
            var $ele = $('#counter5');
            var span = "<span>+</span>";
            $(span).hide().appendTo($ele).fadeIn();

        }
    });

    // 出现在页面执行数字动画
    if ($("#count-box").length > 0) {
        $(window).scroll(function () {
            var window_h = $(window).height();
            var window_val = $(window).scrollTop();
            var $ele = $("#count-box");
            var distance = $ele.offset().top;
            if (window_val >= distance - window_h + $ele.height()) {
                var startNum = 1;
                var endNum = 5;
                var timer = setInterval(function () {
                    if (startNum <= endNum) {
                        doCounter(startNum);
                        startNum++;
                    } else {
                        clearInterval(timer);
                    }
                }, 250);

                function doCounter(startNum) {
                    switch (startNum) {
                        case 1:
                            counter1.run();
                            break;
                        case 2:
                            counter2.run();
                            break;
                        case 3:
                            counter3.run();
                            break;
                        case 4:
                            counter4.run();
                            break;
                        case 5:
                            counter5.run();
                            break;
                    }
                }
            }
        })
    }


    // 首页资讯中心切换
    $('#tabs-home-news-center .am-tabs-nav li').mouseenter(function () {
        $('#tabs-home-news-center').tabs('open', $(this).index());
    })

    // 首页服务切换
    $('#tabs-home-sas .am-tabs-nav li').mouseenter(function () {
        $('#tabs-home-sas').tabs('open', $(this).index());
    })

    // 企业内训切换
    $("#qynx").tabs({
        noSwipe: 1
    });

    // 标杆游学
    $("#bgyx").tabs({
        noSwipe: 1
    });

    // 侧边工具的微信显隐
    $(".wechat").hover(function () {
        $(this).find("img").fadeIn();
    }, function () {
        $(this).find("img").fadeOut();
    })

    // 侧边电话显示隐藏
    $(".side-bar .tel").hover(function () {
        $(this).find(".tel-box").fadeIn();
    }, function () {
        $(this).find(".tel-box").fadeOut();
    })

    // 返回顶部
    $(".back2top").on("click", function () {
        $("body,html").animate({
            "scrollTop": 0
        }, 500)
    })
    $(window).on('scroll', function () {
        if ($(window).scrollTop() > $(window).height()) {
            $(".back2top").fadeIn()
        } else {
            $(".back2top").fadeOut();
        }
    });


    if ($(".left-nav").length > 0) {
        if ($(window).width() > 1024) {
            // 左侧二级导航事件
            $(".left-nav dt").on("click", function () {
                var $dd = $(this).parent("dl").children("dd");
                if ($dd.css("display") == "none") {
                    $dd.slideDown();
                } else {
                    $dd.slideUp();
                }
            })
        }
        // 左侧二级导航跳转Tab
        $(".qynx dd,.bgyx dd").on("click", function (e) {
            e.preventDefault();
            $("#tabs-home-news-center,#qynx,#bgyx").tabs('open', $(this).index() - 1);
        })
    }

    // 走进名人
    $(".zjmr-type li").on("click", function () {
        var index = $(this).index();
        $(".zjmr-type li").removeClass("active").eq(index).addClass("active");
        $(".zjmr-type li").each(function () {
            $(this).css("background-image", "url(" + $(this).attr("grey") + ")");
        });
        $(this).css("background-image", "url(" + $(this).attr("active") + ")");

        $(".zjmr-detail li").removeClass("active").eq(index).addClass("active");
    })

    // 首页虚假登录切换
    $(".ehr .type li").on("click", function () {
        $(".ehr .type li").removeClass("active").eq($(this).index()).addClass("active");
    })


})

// pc/wap，banner切换
document.addEventListener("DOMContentLoaded", function () {

    if ($(".am-slider").length > 0) {
        var srcArr_s = [];
        var dataSrcArr_s = [];
        $(".am-slider img").each(function (a, b) {
            dataSrcArr_s[dataSrcArr_s.length] = b.getAttribute("data-src");
            srcArr_s[srcArr_s.length] = b.getAttribute("src");
        })
        toggleImg(srcArr_s, dataSrcArr_s, $(".am-slider img"));
        window.onresize = function () {
            toggleImg(srcArr_s, dataSrcArr_s, $(".am-slider img"));
        }
    }

    if ($(".banner-container").length > 0 && $(".banner-container img")[0].getAttribute("data-src") !== null) {
        var srcArr_b = [];
        var dataSrcArr_b = [];
        $(".banner-container img").each(function (a, b) {
            dataSrcArr_b[dataSrcArr_b.length] = b.getAttribute("data-src");
            srcArr_b[srcArr_b.length] = b.getAttribute("src");
        })
        toggleImg(srcArr_b, dataSrcArr_b, $(".banner-container img"));
        window.onresize = function () {
            toggleImg(srcArr_b, dataSrcArr_b, $(".banner-container img"));
        }
    }

    // header中的drop-nav
    $("#doc-topbar-collapse .am-nav>li").hover(function () {
        $(this).find(".drop-nav").stop(true, true).slideDown();
    }, function () {
        $(this).find(".drop-nav").stop(true, true).slideUp();
    })

})

function toggleImg(srcArr, dataSrcArr, $ele) {
    var sw = window.screen.width;
    if (sw < 768) {
        $ele.each(function (a, b) {
            b.src = dataSrcArr[a];
        })
    } else {
        $ele.each(function (a, b) {
            b.src = srcArr[a];
        })
    }
}